import React from 'react';
import PropertiesGrid from '.';
import { FindPublicPropertiesResponse } from '../../schemas/properties/PublicPropertyDTO';

type PropertyGridComponentProps = {
  columns: number;
  noPropertiesFoundMessage: string;
} & FindPublicPropertiesResponse;

const LandingPagePropertyGrid = ({ columns, data, pagination, noPropertiesFoundMessage }: PropertyGridComponentProps) => {
  if (!pagination.total) {
    return (
      <div className="my-16 py-6 text-center">
        <span className="text-gray-500">{noPropertiesFoundMessage}</span>
      </div>
    );
  }

  return (
    <div className="relative mx-auto mb-14 mt-14 max-w-[1172px] px-4">
      <PropertiesGrid data={data} columns={columns} pagination={pagination} />
    </div>
  );
};

export default LandingPagePropertyGrid;
