import { GetServerSidePropsContext } from 'next';
import { useRouter } from 'next/router';
import SEO from '../../components/SEO';
import GetStarted from '../../sections/common/GetStarted';
import Page from '../../sections/common/Page';
import PageHero from '../../sections/common/PageHero';
import PropertyCollection from '../../sections/common/PropertyCollection';
import RelatedArticles from '../../sections/common/RelatedArticles';
import RichText from '../../sections/common/RichText';
import { Localized } from '../../types/cms/generalTypes';
import { NewBuildOverviewCMSResult, NewBuildPageCMSResult } from '../../types/cms/newBuildTypes';
import { LanguageKey } from '../../types/common';
import { SharedData } from '../../types/serverData';
import { loadFromCms, loadSharedDataFromCMS } from '../../utils/http';
import { createLanguagePaths, getRoute } from '../../utils/languages';
import { PropertiesGridProps } from 'components/PropertiesGrid';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { getSlugPropertiesServerSide } from 'hooks/usePropertiesSearch';
import { createPostalCodeRange } from 'utils/common';
import LandingPagePropertyGrid from 'components/PropertiesGrid/LandingPagePropertyGrid';

interface NewBuildProps extends SharedData {
  newBuildOverview: NewBuildOverviewCMSResult;
  newBuildPage: NewBuildPageCMSResult;
  propertiesGridProps: PropertiesGridProps;
}

const NewBuild = ({ headerData, footerData, generalData, newBuildOverview, newBuildPage, languagePaths, propertiesGridProps }: NewBuildProps) => {
  const { locale } = useRouter();
  const image = newBuildPage.Image.data.attributes;
  const { data, pagination, columns } = propertiesGridProps;

  return (
    <Page header={{ data: headerData, options: 'white' }} footer={{ data: footerData, languagePaths }}>
      <SEO
        title={newBuildPage?.SEO.Title}
        description={newBuildPage?.SEO.Description}
        languagePaths={languagePaths}
        images={[
          {
            url: image.url,
            alt: newBuildPage.PageTitle,
            width: image.width,
            height: image.height,
          },
        ]}
      />

      <PageHero
        heading={`${newBuildOverview.PreHeadline} ${newBuildPage.PageTitle}`}
        text={newBuildPage.Description}
        image={image.url}
        breadcrumbs={[{ title: newBuildOverview.PageTitle, link: getRoute('/new-build', locale as LanguageKey) }, { title: newBuildPage.PageTitle }]}
        white
      />
      <LandingPagePropertyGrid data={data} pagination={pagination} columns={columns} noPropertiesFoundMessage={generalData.Misc.NoPropertiesFound} />
      <PropertyCollection propertiesData={newBuildPage.Properties} generalTexts={generalData} />
      <RichText spacing="noTop" fromCMS={newBuildPage?.Content} />
      {newBuildPage?.RelatedArticles.Show && newBuildPage?.RelatedArticles.Tag.data && (
        <RelatedArticles
          heading={generalData?.Misc.RelatedArticles}
          tag={newBuildPage?.RelatedArticles.Tag?.data.attributes}
          generalTexts={generalData}
        />
      )}
      <GetStarted fromCMS={newBuildOverview.GetStarted} />
    </Page>
  );
};

export default NewBuild;

export async function getServerSideProps({ locale, params, query }: GetServerSidePropsContext<{ slug: string }>) {
  try {
    const { slug } = params!;
    const sharedDataPromise = loadSharedDataFromCMS(locale as LanguageKey);
    const newBuildOverviewPromise = loadFromCms<NewBuildOverviewCMSResult>('new-build-overview', locale as LanguageKey, [
      'GetStarted.CTA',
      'SEO.OGImage',
    ]);
    const newBuildPagePromise = loadFromCms<Localized<NewBuildPageCMSResult>>(`new-build-pages/${slug}`, locale as LanguageKey, [
      'Image',
      'Properties.Tags',
      'RelatedArticles.Tag.Articles.Category',
      'RelatedArticles.Tag.Articles.Image',
      'SEO.OGImage',
    ]);
    const translationsPromise = serverSideTranslations(locale as LanguageKey);

    const [{ headerData, footerData, generalData }, newBuildOverview, newBuildPage, translations] = await Promise.all([
      sharedDataPromise,
      newBuildOverviewPromise,
      newBuildPagePromise,
      translationsPromise,
    ]);

    const { Tags } = newBuildPage.Properties;
    const tags = Tags?.map((tag) => tag.Entry);
    const page = query.page ? parseInt(query.page as string) : 1;

    const postalCodeRange = createPostalCodeRange(newBuildPage.Properties.FirstPostalCode, newBuildPage.Properties.LastPostalCode);

    const searchPropertiesResponse = await getSlugPropertiesServerSide({ tags, postalCodeRange }, page, 24);
    const languagePaths = createLanguagePaths(newBuildPage, locale as LanguageKey);

    return {
      props: {
        headerData,
        footerData,
        generalData,
        newBuildOverview,
        newBuildPage,
        languagePaths,
        propertiesGridProps: { ...searchPropertiesResponse, columns: 3 },
        ...translations,
      },
    };
  } catch (error) {
    console.error(error);
    return { notFound: true };
  }
}
